import type {
    Dispatch,
    SetStateAction,
    RefObject,
    MutableRefObject,
} from 'react';
import { useEffect, useState } from 'react';
import { useLatest } from 'ahooks';
import { TopNavTransitionState } from '../types';

export const useHandleTopNavTransitions = (
    topNavRef: RefObject<HTMLDivElement>
): TopNavTransitionState => {
    const navState = useLatest(useState<TopNavTransitionState>('static'));

    useEffect(() => {
        const scrollListenerHandler = makeScrollListenerHandler(
            topNavRef,
            navState
        );
        window.addEventListener('scroll', scrollListenerHandler);

        return () => {
            window.removeEventListener('scroll', scrollListenerHandler);
        };
    }, [navState, topNavRef]);

    return navState.current[0];
};

const makeScrollListenerHandler = (
    topNavRef: RefObject<HTMLDivElement>,
    navStateRef: MutableRefObject<
        [TopNavTransitionState, Dispatch<SetStateAction<TopNavTransitionState>>]
    >
): (() => void) => {
    let previousScrollPosition = 0;
    let isTriggered = false;
    let ticking = false;
    const topnavHeight = topNavRef.current?.getBoundingClientRect().height || 0;
    const threshold = 50;

    return () => {
        if (!topNavRef.current) return;

        if (!ticking) {
            window.requestAnimationFrame(() => {
                const [currentNavState, setNavState] = navStateRef.current;
                const lastKnownScrollPosition = window.scrollY;

                const hasScrolledUp =
                    lastKnownScrollPosition - previousScrollPosition < 0;
                if (lastKnownScrollPosition < 1) {
                    setNavState('static');
                    isTriggered = false;
                    if (
                        hasScrolledUp &&
                        topNavRef.current?.classList.contains('in')
                    ) {
                        // Prevents nav transitioning in when user scrolls to top of page
                        topNavRef.current?.classList.remove('in');
                    }
                } else {
                    const scrolledPastTopNav =
                        lastKnownScrollPosition > topnavHeight + threshold;

                    if (!isTriggered) {
                        if (hasScrolledUp && scrolledPastTopNav) {
                            setNavState('slide-in');
                            isTriggered = true;
                        }
                    } else {
                        const hasScrolledDown =
                            lastKnownScrollPosition - previousScrollPosition >
                            0;

                        if (
                            hasScrolledDown &&
                            scrolledPastTopNav &&
                            currentNavState === 'slide-in'
                        ) {
                            setNavState('slide-out');
                            isTriggered = false;
                        }
                    }
                }

                previousScrollPosition = lastKnownScrollPosition;
                ticking = false;
            });

            ticking = true;
        }
    };
};
